<template>
  <el-container>
    <Header pageName="账号管理"></Header>
    <el-main>
      <el-form class="el-form-search" label-width="100px">
        <el-form-item label="登录账号：">
          <el-input size="small" v-model="searchItem.keyword" placeholder="请输入登录账号"></el-input>
        </el-form-item>
        <el-form-item label="账号日期：">
          <el-date-picker v-model="searchItem.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="30px">
          <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
          <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="u_account" label="登录账号" align="center"></el-table-column>
        <el-table-column prop="u_password" label="登录密码" align="center"></el-table-column>
        <el-table-column prop="total_account_num" label="充值总计站点数量" align="center"></el-table-column>
        <el-table-column prop="account_num" label="充值剩余站点数" align="center"></el-table-column>
        <el-table-column prop="already_account_num" label="实际站点总数 " align="center"></el-table-column>
        <el-table-column prop="expired_account_num" label="过期站点总数" align="center"></el-table-column>
        <el-table-column label="账号日期" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="text" size="small" @click="openRightDrawer(0, row)">充值</el-button>
            <el-button type="text" size="small" @click="openRightDrawer(1, row)">编辑</el-button>
            <el-button type="text" size="small" @click="lookRecordList(row)">记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-drawer title="编辑" :visible.sync="updateFlag" direction="rtl">
      <el-form label-width="150px" class="drawer-form">
        <el-form-item label="登录账号：">
          <div>{{ chooseThat.u_account }}</div>
        </el-form-item>
        <el-form-item label="登录密码：">
          <el-input show-password size="small" v-model="chooseThat.u_password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button size="small" @click="() => (updateFlag = false)">取消</el-button>
        <el-button size="small" type="primary" @click="sureUpdate">确定</el-button>
      </div>
    </el-drawer>
    <el-drawer title="充值" :visible.sync="rechargeFlag" direction="rtl">
      <el-form label-width="150px" class="drawer-form">
        <el-form-item label="充值站点数量：">
          <el-input size="small" v-model="chooseThat.recharge_num" placeholder="请输入总计站点数量："></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="chooseThat.ramark" rows="6" show-word-limit></el-input>
        </el-form-item>
        <div class="btnBox">
          <el-button size="small" @click="() => (rechargeFlag = false)">取消</el-button>
          <el-button size="small" type="primary" @click="sureRecharge">确定</el-button>
        </div>
      </el-form>
    </el-drawer>
    <!-- 记录弹框 -->
    <el-dialog title="记录" :visible.sync="recordDialog" width="1200px">
      <el-table :data="recordList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column label="事件时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
        </el-table-column>
        <el-table-column label="站点账号" align="center">
          <template v-slot="{ row }">{{ row.uniacid_info ? row.uniacid_info.name : '--' }}</template>
        </el-table-column>
        <el-table-column prop="num" label="站点变更数量" align="center">
          <template v-slot="{ row }">
            <div class="rowClass">
              <span :style="{ color: row.uniacid === 0 ? 'red' : 'green' }">{{ row.uniacid === 0 ? '+' : '-' }}{{ row.num }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="到期时间" align="center">
          <template v-slot="{ row }">{{ row.uniacid_info ? getDateformat(row.uniacid_info.endtime) : '--' }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
      <Paging :total="recordTotal" :page="recordPage" :pageNum="recordRows" @updatePageNum="updateRecordData"></Paging>
    </el-dialog>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate.js';
import _ from 'lodash';
export default {
  components: {
    Header,
    Paging,
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        keyword: '',
        create_time: [],
      },
      dataList: [],
      page: 1,
      rows: 10,
      total: 0,
      updateFlag: false,
      rechargeFlag: false,
      chooseThat: {},
      recordDialog: false,
      recordList: [],
      recordPage: 1,
      recordRows: 10,
      recordTotal: 0,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 清空搜索条件
    cancelSearch() {
      this.searchItem = {
        keyword: '',
        create_time: [],
      };
      this.getDataList(1);
    },
    // 分页查询
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    updateRecordData(val, status) {
      if (status == 0) {
        this.recordRows = val;
      } else {
        this.recordPage = val;
      }
      this.getRecordList(this.chooseThat);
    },
    // 获取数据列表
    getDataList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchItem.keyword) {
        obj.keyword = this.searchItem.keyword;
      }
      if (this.searchItem.create_time && this.searchItem.create_time.length) {
        obj.create_time = [];
        obj.create_time[0] = Math.floor(this.searchItem.create_time[0] / 1000);
        obj.create_time[1] = Math.floor(this.searchItem.create_time[1] / 1000);
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.admin.accountManagement.getAll, obj).then(res => {
        if (res.code == 0) {
          this.dataList = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 打开右侧抽屉
    openRightDrawer(status, row) {
      this.chooseThat = _.cloneDeep(row);
      this.$set(this.chooseThat, 'recharge_num', '');
      this.$set(this.chooseThat, 'ramark', '');
      if (status === 1) {
        this.updateFlag = true;
      } else if (status === 0) {
        this.rechargeFlag = true;
      }
    },
    // 确认修改
    sureUpdate() {
      if (this.chooseThat.u_password === '') {
        this.$message.error('请输入密码');
        return;
      }
      let obj = {
        u_id: this.chooseThat.u_id,
        u_password: this.chooseThat.u_password,
      };
      this.$axios.post(this.$api.admin.accountManagement.userUpdate, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('修改密码成功');
          this.getDataList();
          this.updateFlag = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 确认充值站点
    sureRecharge() {
      if (isNaN(Number(this.chooseThat.recharge_num)) || Number(this.chooseThat.recharge_num) <= 0) {
        this.$message.error('请输入符合规则的站点数');
        return;
      }
      let obj = {
        u_id: this.chooseThat.u_id,
        remark: this.chooseThat.ramark,
        num: this.chooseThat.recharge_num,
      };
      this.$axios.post(this.$api.admin.accountManagement.recharge, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('充值成功');
          this.getDataList();
          this.rechargeFlag = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    lookRecordList(val) {
      this.chooseThat = val;
      this.getRecordList(val);
      this.recordDialog = true;
    },
    getRecordList(val) {
      let obj = {
        page: this.recordPage,
        rows: this.recordRows,
        u_id: val.u_id,
      };
      this.$axios.post(this.$api.admin.accountManagement.userAccountLog, obj).then(res => {
        if (res.code === 0) {
          this.recordList = res.result.list;
          this.recordTotal = res.result.count;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
  }
  .drawer-form {
    padding-right: 50px;
  }
  .btnBox {
    padding-right: 50px;
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
  }
  .rowClass {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
